@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "../node_modules//bootstrap-icons/font/bootstrap-icons.css";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,700;1,100;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Uchen&display=swap");

.app {
  font-family: "Montserrat", sans-serif;
  background: linear-gradient(#f1faee, #a8dadc, #e63946);
}

.logo {
  font-family: "Uchen", serif;
}

.toogle-button {
  display: none;
  width: 30px;
  height: 20px;
}

.bar {
  height: 3px;
  width: 20px;
  background-color: black;
  border-radius: 10px;
}

.nav-links {
  &:hover {
    border-bottom: 2px solid #1d3557;
    transition: 0.1s ease-in-out;
  }
}

.links {
  display: flex;
  justify-content: space-evenly;
}

a {
  text-decoration: none;
}

.pic {
  background-image: url("./assets/Portrait.jpg");
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  border: 5px solid #e63946;
  width: 350px;
  height: 350px;
  margin: 3rem auto;
}

.project-list {
  // background: linear-gradient(#0000003d, #0000003d);
  // border-radius: 10px;

  img {
    width: 100%;
    border-radius: 10px;
    height: 350px;
  }

  a {
    text-decoration: none;
  }
}

#about {
  padding-bottom: 5rem;
}

#skills {
  // background: linear-gradient(#e63947bb, #e63947bb);
  background-position: center;
  background-size: cover;
  padding-bottom: 3rem;
}

.skill-list {
  // background: linear-gradient(#0000003d, #0000003d);
  border-radius: 10px;
}

.job-list {
  border-top: 1px solid rgb(65, 65, 65);
}

.foot-links {
  .resume {
    border: 1px solid white;
    background-color: rgba(255, 255, 255, 0);
    border-radius: 50px;
    color: white;

    &:hover {
      background-color: rgb(255, 255, 255);
      color: black;
    }
  }

  a {
    text-decoration: none;
  }

  i {
    font-size: 2rem;
    color: white;

    &:hover {
      color: rgb(0, 0, 0);
    }
  }
}

@media (max-width: 500px) {
  .project-list {
    img {
      height: 150px;
    }
  }
}

@media (max-width: 1000px) {
  .toggle-button {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 1.25rem;
    left: 85%;
  }

  .links {
    display: none;
    flex-direction: column;
    width: 100% !important;
    text-align: center;
    position: absolute;
    top: 3rem;
    left: 0.5%;
    padding: 1rem;

    &.active {
      display: flex;
    }
  }

  .nav-links {
    &:hover {
      border-bottom: none;
    }
  }
}
